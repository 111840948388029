import request from '@/utils/request'


// 查询用户完成广告记录列表
export function listUserLookAds(query) {
  return request({
    url: '/platform/user-look-ads/list',
    method: 'get',
    params: query
  })
}

// 查询用户完成广告记录分页
export function pageUserLookAds(query) {
  return request({
    url: '/platform/user-look-ads/page',
    method: 'get',
    params: query
  })
}

// 查询用户完成广告记录分页
export function numUserLookAds(query) {
  return request({
    url: '/platform/user-look-ads/adminPageNum',
    method: 'get',
    params: query
  })
}

// 查询用户完成广告记录详细
export function getUserLookAds(data) {
  return request({
    url: '/platform/user-look-ads/detail',
    method: 'get',
    params: data
  })
}

// 新增用户完成广告记录
export function addUserLookAds(data) {
  return request({
    url: '/platform/user-look-ads/add',
    method: 'post',
    data: data
  })
}

// 修改用户完成广告记录
export function updateUserLookAds(data) {
  return request({
    url: '/platform/user-look-ads/edit',
    method: 'post',
    data: data
  })
}

// 删除用户完成广告记录
export function delUserLookAds(data) {
  return request({
    url: '/platform/user-look-ads/delete',
    method: 'post',
    data: data
  })
}
